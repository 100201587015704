// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-example-test-page-js": () => import("./../../../src/pages/example-test-page.js" /* webpackChunkName: "component---src-pages-example-test-page-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-js": () => import("./../../../src/pages/installation.js" /* webpackChunkName: "component---src-pages-installation-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-more-js": () => import("./../../../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */)
}

